

/*$(function() {
    $('#faq1').on('click', function(e) {
      $('#accordion .collapse').collapse('toggle')
    })

    $('#faq2').on('click', function(e) {
        $('#accordion2 .collapse').collapse('toggle')
      })

      $('#faq3').on('click', function(e) {
        $('#accordion3 .collapse').collapse('toggle')
      })
  });
  */
  
  $(function() {
  $("input").on("input.highlight", function() {
    // Determine specified search term
    var searchTerm = $(this).val();
    // Highlight search term inside a specific context
    $(".tab-content").unmark().mark(searchTerm);
  }).trigger("input.highlight").focus();
});
  
$(".search-query").each(
	function(){
		$(this).data('holder',$(this).attr('placeholder'));
		$(this).focusin(function(){
			$(this).attr('placeholder','');
		});
		$(this).focusout(function(){
			$(this).attr('placeholder',$(this).data('holder'));
		});
		
});
  
$("#faq1 input").on('click',function() {
    if($(this).is(":checked")) {
         $('#menu1 h4 a').removeClass('collapsed');
		 $('#menu1 .panel-collapse').addClass('in');
    } else {
       $('#menu1 h4 a').addClass('collapsed');
	   $('#menu1 .panel-collapse').removeClass('in');
    }
});

$("#faq2 input").on('click',function() {
    if($(this).is(":checked")) {
		 $('#menu2 h4 a').removeClass('collapsed');
		 $('#menu2 .panel-collapse').addClass('in');
    } else {
	   $('#menu2 h4 a').addClass('collapsed');
	   $('#menu2 .panel-collapse').removeClass('in');
    }
});

$("#faq3 input").on('click',function() {
    if($(this).is(":checked")) {
         $('#menu3 h4 a').removeClass('collapsed');
		 $('#menu3 .panel-collapse').addClass('in');
    } else {
       $('#menu3 h4 a').addClass('collapsed');
	   $('#menu3 .panel-collapse').removeClass('in');
    }
});
   